<template>
  <v-app>
    <v-main>
      <v-container>
        <v-sheet>
          <v-row>
            <v-col cols="12" sm="5" lg="3">
              <v-row>
                <v-col>
                 
                  <v-sheet
                    width=""
                    rounded
                    height=""
                    dark
                    elevation="5"
                    color="indigo darken-4"
                    class="mt-8"
                  >
                  
                    <v-row justify="start">
                      <v-col cols="12">
                        <div
                          v-text="'AUDIDOC'"
                          class="headline font-weight-light ml-10"
                        ></div>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row>
                <v-divider class="mr-3" vertical></v-divider>
                <v-col>
                 
                  <v-sheet class="mt-2">
                    <v-row>
                      <v-col cols="">
                        <v-list rounded>
                          <v-subheader
                            v-text="'Casos'"
                            class="header font-weight-light"
                          ></v-subheader>
                          <v-list-group
                            :value="false"
                            prepend-icon="mdi-account-circle"
                          >
                            <template v-slot:activator>
                              <v-list-item-title>
                                <div
                                  v-text="'Asignados'"
                                  class="subtitle font-weight-light"
                                ></div>
                              </v-list-item-title>
                            </template>
                            <v-list-item-group
                              color="primary"
                              v-model="selectItem_asignados"
                            >
                              <v-list-item
                                link
                                @click="casos_asignados('Pendiente')"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="'Pendientes'"
                                    class="subtitle font-weight-light"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                link
                                @click="casos_asignados('Aceptado')"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="'Aceptados'"
                                    class="subtitle font-weight-light"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                link
                                @click="casos_asignados('Rechazado')"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="'Rechazados'"
                                    class="subtitle font-weight-light"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                               <v-list-item
                                link
                                @click="casos_asignados('Reasignado')"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="'Re-asigngados'"
                                    class="subtitle font-weight-light"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list-group>
                          <v-list-group
                            :value="false"
                            prepend-icon="mdi-account-circle"
                          >
                            <template v-slot:activator>
                              <v-list-item-title>
                                <div
                                  v-text="'Delegados'"
                                  class="subtitle font-weight-light"
                                ></div>
                              </v-list-item-title>
                            </template>
                            <v-list-item-group
                              color="primary"
                              v-model="selectItem_delegados"
                            >
                              <v-list-item
                                link
                                @click="casos_delegados('Pendiente')"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="'Pendientes'"
                                    class="subtitle font-weight-light"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                link
                                @click="casos_delegados('Aceptado')"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="'Aceptados'"
                                    class="subtitle font-weight-light"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                link
                                @click="casos_delegados('Rechazado')"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="'Rechazados'"
                                    class="subtitle font-weight-light"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                link
                                @click="casos_delegados('Respondido')"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="'Respondidos'"
                                    class="subtitle font-weight-light"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list-group>
                          <v-list-group
                            :value="false"
                            prepend-icon="mdi-account-circle"
                          >
                            <template v-slot:activator>
                              <v-list-item-title>
                                <div
                                  v-text="'Enviados'"
                                  class="subtitle font-weight-light"
                                ></div>
                              </v-list-item-title>
                            </template>
                            <v-list-item-group
                              color="primary"
                              v-model="selectItem_enviados"
                            >
                              <v-list-item
                                link
                                @click="
                                  respuestas_casos_respondidos('Aceptado')
                                "
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="'Aceptados'"
                                    class="subtitle font-weight-light"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                link
                                @click="
                                  respuestas_casos_respondidos('Rechazado')
                                "
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="'Rechazados'"
                                    class="subtitle font-weight-light"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list-group>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
                <v-divider class="ml-n3" vertical></v-divider>
              </v-row>
            </v-col>
           
            <v-col cols="12" sm="7" lg="9">
              <router-view></router-view>
            </v-col>
          </v-row>
          

          <!--- Crear componente que tenga la bandeja de entrada y se le pasan los elementos -->
        </v-sheet>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
//
export default {
  components: {},

  data() {
    return {
     
      selectItem_asignados: 0,
      selectItem_delegados: 0,
      selectItem_enviados:0,
    };
  },
  watch: {
     ruta() {
       console.log(this.$route)
       this.redireccion(this.$route.name, this.$route.params)

    },
  },
  created() {
    //this.casos_asignados("Pendiente");
    this.$store.state.archivos_seleccionados = []
  },
  

  methods: {
    //Estas 3 funciones pueden hacerse una sola parametrizando el name
    redireccion(name, params) {
      console.log('HOLA')
      console.log(this.$route.name)
      console.log(name)
     if(this.$route.name!=name){
       this.$router.push({
        name: name,
        params: params,
      });
     }
      
    },
    respuestas_casos_respondidos(tipo) {
      this.$store.state.tipo_para_arreglar_problas_de_ruta = tipo;
      tipo = btoa(tipo) //Enconde 
      this.$router.push({
        name: "CasosRespondidos",
        params: { id: tipo },
      });
    },
    casos_asignados(tipo) {
      this.$store.state.tipo_para_arreglar_problas_de_ruta = tipo;
      tipo = btoa(tipo) //Enconde 
      this.$router.push({
        name: "CasosAsignados",
        params: { id: tipo },
      });
    },
    casos_delegados(tipo) {
      this.$store.state.tipo_para_arreglar_problas_de_ruta = tipo;
       tipo = btoa(tipo) //Enconde 
      this.$router.push({
        name: "CasosDelegados",
        params: { id: tipo },
      });
    },
  },
  computed: {
    ruta() {
      //Se creo esta funcion computada puesto que no estaba detectando la nueva ruta cada vez que se creaba

      console.log(this.$route.params.id);
      return this.$route.params.id;
    },
  },
  mounted() {
    this.casos_asignados("Pendiente");
  },
};
</script>